import React from "react"
import Utils from "../../utils/node.utils"
import { StaticQuery, graphql } from "gatsby"
import Layout from "../../components/Layout/Layout/Layout"
import SEO from "../../components/SEO/SEO"
import BlogView from "../../components/Blog/BlogView"
import Header from "../../components/Shop/Header/Header"

const BlogPage = ({ location }) => {
  Utils.NavigateToBrowserLanguage(
    "es",
    "/blog",
    location.state,
    location.search
  )
  return (
    <StaticQuery
      query={pageQuery}
      render={data => {
        const commonContentMap = Utils.ContentListToMap(
          data.commonContent.item.content
        )
        const contentMap = Utils.ContentListToMap(data.blogContent.item.content)
        return (
          <Layout
            origin={location.origin}
            originPage="blog"
            language="es"
            commonContentMap={commonContentMap}
          >
            <SEO
              title={contentMap.seo_title.value}
              description={contentMap.seo_description.value}
              keywords={Utils.GetKeywordsFromTags(data.blogContent.item.tags)}
              selfLink="blog"
            />
            <Header
              breadCrumbs={["menu_home", "menu_blog"]}
              commonContentMap={commonContentMap}
            />
            <BlogView
              language="es"
              blogs={data.blogContent.item.page_children}
              contentMap={contentMap}
            />
          </Layout>
        )
      }}
    />
  )
}

export default BlogPage

const pageQuery = graphql`
  {
    blogContent: blogContentEses {
      item {
        key
        tags {
          content {
            key
            value
          }
        }
        page_children {
          id
          key
          slug
          content {
            key
            value
          }
        }
        content {
          key
          value
        }
      }
    }

    commonContent: commonContentEses {
      item {
        key
        content {
          key
          value
        }
      }
    }
  }
`

// page_children {
//   id
//   key
//   slug
//   content {
//     key
//     value
//   }
// }
